import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import LandingPage from "../pages/LandingPage";
import DynamicLinkGenerator from "../pages/DynamicLinkGenerator";
import BlogPage from "../pages/BlogPage";
import BlogPostReader from "../components/Blog/BlogPostReader";
import ContactUsPage from "../pages/ContactUsPage";
import ContactSalesPage from "../pages/ContactSalesPage";
import PricingPage from "../pages/PricingPage";
import PrivacyPolictyPage from "../pages/PrivacyPolictyPage";
import TermsPage from "../pages/TermsPage";
import SecurityPage from "../pages/SecurityPage";
import SLAPage from "../pages/SLAPage";

export const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "security",
        element: <SecurityPage />,
      },
      {
        path: "sla",
        element: <SLAPage />,
      },
      {
        path: "create",
        element: <DynamicLinkGenerator />,
      },
      {
        path: "contact",
        element: <ContactUsPage />,
      },
      {
        path: "sales",
        element: <ContactSalesPage />,
      },
      {
        path: "pricing",
        element: <PricingPage />,
      },
      {
        path: "privacy",
        element: <PrivacyPolictyPage />,
      },
      {
        path: "terms",
        element: <TermsPage />,
      },
      {
        path: "blog",
        element: <BlogPage />,
      },
      {
        path: "/blog/:id",
        element: <BlogPostReader />,
      },
    ],
  },
]);
