import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../assets/style/colors";
import TrackingComponent from "../components/Tracking/TrackingComponent";

const SecurityPage = () => {
  return (
    <Container>
      <LeftSide>
        <h1>Security</h1>
      </LeftSide>

      <RightSide>
        <section>
          <p>
            At Grovs, we take security very seriously, even though we’re a small
            team. We understand that protecting your data is important, so we
            make sure our systems are safe and secure.
          </p>

          <h1>General Security Practices</h1>
          <ul>
            <li>
              We use strong two-factor authentication (2FA) to protect access to
              our servers, databases, source code, and third-party tools.
              Whenever possible, we prefer non-SMS 2FA.
            </li>

            <li>
              We always create strong, unique passwords that we never reuse.
            </li>
            <li>
              Every team member uses 2FA, and we require strong passwords,
              regardless of which method of 2FA is available. Our priority is
              using hardware keys, followed by TOTP (app-based), SMS, and email
              for 2FA.
            </li>
            <li>
              We make it easy for you to protect your account too, offering TOTP
              2FA for all users.
            </li>
            <li>
              Since we’re a small team (just the founders 👋), contractors only
              get access to what they absolutely need to do their jobs.
            </li>
            <li>
              We use tools like GitHub Advanced Security to detect
              vulnerabilities in our code and quickly apply updates. Heroku
              handles our infrastructure security.
            </li>
            <li>
              We do regular vulnerability scans and security tests to stay on
              top of our security.
            </li>
            <li>
              We choose third-party tools that have strong privacy and security
              measures, which align with our values.
            </li>
            <li>
              We ensure our API code is thoroughly tested. If a bug or security
              issue is found, we write tests to make sure it doesn’t happen
              again.
            </li>
            <li>
              We never copy production data to personal devices or external
              storage.
            </li>
          </ul>

          <h1>Infrastructure</h1>

          <ul>
            <li>
              Our servers are hosted by{" "}
              <a href="https://heroku.com" target="null">
                Heroku
              </a>
              , which uses{" "}
              <a href="https://aws.amazon.com" target="null">
                Amazon Web Services (AWS)
              </a>
              . This means our servers and data are managed securely through
              AWS’s powerful infrastructure. We also use{" "}
              <a href="https://cloudflare.com" target="null">
                Cloudflare
              </a>{" "}
              for hosting distribution files. Both Cloudflare and AWS maintain
              top-tier security standards and undergo regular third-party
              audits.
            </li>
            <li>
              Our main servers are located in European data centers, but we also
              offer storage in US data centers if needed. We keep encrypted
              backups in different locations within Europe, and some
              distribution files are stored in the US region for some customers.
            </li>
            <li>
              Amazon continually manages risk and undergoes recurring
              assessments to ensure compliance with industry standards. Amazon's
              data center operations have been accredited under:
              <ul>
                <li>ISO 27001</li>
                <li>
                  SOC 1 and SOC 2/SSAE 16/ISAE 3402 (Previously SAS 70 Type II)
                </li>
                <li>PCI Level 1</li>
                <li>FISMA Moderate</li>
                <li>Sarbanes-Oxley (SOX)</li>
              </ul>
            </li>
          </ul>

          <h1>Authorization</h1>

          <ul>
            <li>
              We operate a multi-tenant application using row-level
              multitenancy. We have extensive unit tests and multiple failsafes
              at the database- and application-level concerning data
              authorization and isolation.
            </li>
            <li>
              We use role-based access control (RBAC) for permissions. Each API
              access token has a role that limits what it can do, ensuring only
              authorized users can access or manage certain resources.
            </li>
          </ul>

          <h1>Encryption</h1>

          <ul>
            <li>
              All communication between our service, your software, and our
              backend is protected by strong encryption (TLS). We use Automated
              Certificate Management provided by Cloudflare. User data is stored
              in Heroku PostgreSQL and details of their implementation can be
              found on the Security page at Heroku.
            </li>
            <li>
              We use 256-bit encryption at all levels of our systems. We enforce
              TLS (HTTPS) to protect sensitive data transmitted to and from
              applications i.e. data in-transit.
            </li>
            <li>
              All data is encrypted at-rest with industry-standard AES-256
              block-level storage encryption. Keys are securely managed by
              Amazon EBS.Highly-sensitive data, such as private keys and
              secrets, is encrypted at-work using AES-256-GCM encryption.
            </li>
          </ul>

          <h1>Payments</h1>

          <ul>
            <li>
              Credit card and bank information is encrypted, stored, and
              processed by Stripe with AES-256 encryption. We process credit
              card and bank information securely through Stripe, using AES-256
              encryption. We don’t store sensitive payment data on our servers.
              Stripe manages all the payment processing, and we store a
              temporary token to refer to the payment.
            </li>
            <li>All communication with Stripe is encrypted using TLS.</li>
          </ul>

          <h1>Backups and Recovery</h1>
          <ul>
            <li>
              We rely on Heroku Postgres to back up our data continuously, using
              snapshots and backups stored securely on AWS S3.
            </li>
            <li>
              Fork, follower, and recovery databases are created by retrieving
              these backups and replaying them on a new Postgres installation.
              This secure storage allows for complete database recovery in case
              of hardware failures, data corruption, or significant service
              interruptions.
            </li>
          </ul>

          <h1>Crashes and Errors</h1>
          <ul>
            <li>
              We monitor for crashes and errors closely and work to fix them
              quickly. We aim for 99.99% uptime and offer an SLA for enterprise
              customers.
            </li>
          </ul>

          <br></br>
          <h1>FAQs</h1>

          <h2>What user data do you collect?</h2>

          <p>
            We don’t make money from your data. We collect some basic
            information about how users interact with our system, like API
            requests and sign-ins, so we can improve the service and help you
            better. For more details, see our privacy policy.
          </p>

          <h2>How long is data kept, and can I have it removed?</h2>
          <p>
            We keep server logs for up to 30 days and account analytics for up
            to 90 days. You can ask us to delete your data at any time.
          </p>

          <h2>Do you fill out security questionnaire?</h2>
          <p>
            Since we’re a small team, we don’t fill out security questionnaires
            for customers on our Scale Up tiers. However, for Enterprise
            customers, we can make an exception. If you have a question that
            isn’t answered here, let us know.
          </p>

          <h2>Do you have security certifications like SOC 2 or ISO 27001?</h2>
          <p>
            We don’t have these certifications yet, but we hope to in the
            future. If you’re interested in working with us on them, feel free
            to reach out!
          </p>

          <h2>Is your system highly available?</h2>
          <p>
            Yes! We have automatic scaling, health checks, and failovers in
            place to ensure high availability.
          </p>

          <h2>
            How do I report a potential vulnerability or security concern?
          </h2>
          <p>
            If you find something, email us at{" "}
            <a href="mailto:support@grovs.io">support@grovs.io</a>, and we’ll
            address it as soon as possible.
          </p>

          <h2>Any other questions?</h2>
          <p>
            We’re happy to answer any questions. Just email us at{" "}
            <a href="mailto:support@grovs.io">support@grovs.io</a>, and we’ll
            update this page if needed.{" "}
          </p>
        </section>
      </RightSide>
      <TrackingComponent></TrackingComponent>
    </Container>
  );
};

export default SecurityPage;

const Container = styled.div`
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 80px;
  @media (max-width: 990px) {
    padding: 20px;
    gap: 50px;
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: left;
  }
`;
const RightSide = styled(LeftSide)`
  gap: 60px;

  h1 {
    margin-top: 10px;
    color: ${lightThemeColors.darkColor};
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }

  h2 {
    color: ${lightThemeColors.darkColor};
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }

  ul {
    // list-style-position: inside; /* Ensures the dot stays inside the padding/margin area */
    padding-left: 20px; /* Adds padding to the left for the entire list */
  }

  li {
    margin-top: 16px;
    text-indent: 0px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  section {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  @media (max-width: 990px) {
    gap: 40px;
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 20px;
    }
    section {
      gap: 20px;
    }
  }
`;
