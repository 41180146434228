import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../assets/style/colors";
import TrackingComponent from "../components/Tracking/TrackingComponent";

const SLAPage = () => {
  return (
    <Container>
      <LeftSide>
        <h1>Service Level Agreement (SLA)</h1>
        <p>Effective Date: September 21, 2024</p>
      </LeftSide>

      <RightSide>
        <section>
          <h1>1. Service Availability</h1>
          <p>
            Grovs ("we", "us", or "our") guarantees a{" "}
            <b>
              99.99% Uptime Commitment for customers with an Enterprise plan
            </b>
            . If we fail to meet this uptime guarantee and your account is
            impacted, we will add a Service Credit to your account for future
            use.
          </p>

          <h1>2. What Uptime Means</h1>
          <p>
            Uptime is the percentage of time Grovs services are working during a
            fiscal quarter. Our goal is to have at least 99.99% Uptime,
            calculated as: ((Total minutes in the quarter - Unscheduled
            Downtime) ÷ Total minutes in the quarter) ≥ 99.99%.
          </p>

          <h1>3. What Counts as Unscheduled Downtime</h1>
          <p>
            Unscheduled Downtime happens when our services stop working
            unexpectedly and can’t perform their usual operations. Unscheduled
            Downtime does not include:
          </p>

          <ul>
            <li>
              Issues caused by third-party vendors or the internet itself.
            </li>
            <li>Problems beyond our control (e.g., natural disasters).</li>
            <li>
              Failures caused by your software, hardware, or third-party tools.
            </li>
            <li>
              Slowness or bugs in specific features (like dashboards or logs).
            </li>
            <li>Scheduled maintenance.</li>
          </ul>

          <p>
            The length of Unscheduled Downtime is calculated in minutes,
            starting when the services stop working and ending when they are
            operational again. We track these outages using system logs.
          </p>

          <h1>4. Scheduled Downtime</h1>
          <p>
            Scheduled Downtime happens when we plan maintenance, backups, or
            upgrades. We will notify you at least 48 hours in advance if our
            services will be unavailable.
          </p>

          <p>Key details</p>
          <ul>
            <li>Scheduled Downtime will not exceed 8 hours per month.</li>
            <li>
              Maintenance is scheduled during off-peak hours (Central Time).
            </li>
            <li>
              We will notify you via email regarding any scheduled maintainance.
            </li>
            <li>
              We calculate the length of Scheduled Downtime in minutes, just
              like Unscheduled Downtime.
            </li>
          </ul>

          <h1>5. Service Credits for Downtime</h1>
          <p>
            If we don’t meet our Uptime Commitment, we will issue a Service
            Credit to your account:
          </p>

          <ul>
            <li>
              Credit is 10 times the amount you paid for the time services were
              down.
            </li>
            <li>
              Credits are capped at 50% of your monthly payment for that billing
              cycle.
            </li>
          </ul>

          <h2>How Service Credits Work:</h2>

          <ul>
            <li>Credits apply to future use of our services.</li>
            <li>Credits will be added within 60 days after approval.</li>
            <li>
              Credits are valid only if your account has no unpaid invoices.
            </li>
            <li>Credits expire if your contract ends.</li>
          </ul>

          <h2>How to Request a Credit:</h2>

          <ul>
            <li>Notify us in writing within 30 days of the downtime.</li>
            <li>Provide logs showing when the downtime occurred.</li>
            <li>
              If you miss this deadline, you lose the right to claim credits.
            </li>
          </ul>

          <p>
            In case of disputes, we’ll determine the issue based on system logs
            and other available data.
          </p>

          <h1>6. SLA Updates</h1>
          <p>
            We may update this SLA over time as our business evolves. You can
            always find the latest version on this page.
          </p>
        </section>
      </RightSide>
      <TrackingComponent></TrackingComponent>
    </Container>
  );
};

export default SLAPage;

const Container = styled.div`
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 80px;
  @media (max-width: 990px) {
    padding: 20px;
    gap: 50px;
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: left;
  }
`;
const RightSide = styled(LeftSide)`
  gap: 60px;

  h1 {
    margin-top: 10px;
    color: ${lightThemeColors.darkColor};
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }

  h2 {
    color: ${lightThemeColors.darkColor};
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }

  ul {
    // list-style-position: inside; /* Ensures the dot stays inside the padding/margin area */
    padding-left: 20px; /* Adds padding to the left for the entire list */
  }

  li {
    margin-top: 16px;
    text-indent: 0px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  section {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  @media (max-width: 990px) {
    gap: 40px;
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 20px;
    }
    section {
      gap: 20px;
    }
  }
`;
