import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import { ReactComponent as GitIcon } from "../../assets/icons/socialMediaIcons/footer-git.svg";
import { ReactComponent as LinnkedInIcon } from "../../assets/icons/socialMediaIcons/footer-linkedin.svg";
import { ReactComponent as Logo } from "../../assets/icons/footer-logo.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <FullPageContainer>
      <Container>
        <Line style={{ width: "100%" }}>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <LinksWrapper>
            <Link
              to={"/terms"}
              style={{
                color: lightThemeColors.textColor,
                fonstSize: "14px",
                textAlign: "center",
                lineHeight: "20px",
              }}
            >
              Terms of Service
            </Link>
            <Link
              to={"/privacy"}
              style={{
                color: lightThemeColors.textColor,
                fonstSize: "14px",
                textAlign: "center",
                lineHeight: "24px",
              }}
            >
              Privacy Policy
            </Link>
            <Link
              to={"/contact"}
              style={{
                color: lightThemeColors.textColor,
                fonstSize: "14px",
                textAlign: "center",
                lineHeight: "24px",
              }}
            >
              Contact us
            </Link>
            <Link
              onClick={() =>
                window.open("https://docs.grovs.io/s/docs", "_blank")
              }
              style={{
                color: lightThemeColors.textColor,
                fonstSize: "14px",
                textAlign: "center",
                lineHeight: "24px",
              }}
            >
              Documentation
            </Link>
            <Link
              to={"/security"}
              style={{
                color: lightThemeColors.textColor,
                fonstSize: "14px",
                textAlign: "center",
                lineHeight: "24px",
              }}
            >
              Security
            </Link>
            <Link
              to={"/sla"}
              style={{
                color: lightThemeColors.textColor,
                fonstSize: "14px",
                textAlign: "center",
                lineHeight: "24px",
              }}
            >
              SLA
            </Link>
          </LinksWrapper>

          <RightIconWrapper>
            <FooterBtn
              onClick={() =>
                window.open("https://www.linkedin.com/company/grovs/", "_blank")
              }
            >
              <LinnkedInIcon />
            </FooterBtn>
            <FooterBtn
              onClick={() =>
                window.open("https://github.com/grovs-io", "_blank")
              }
            >
              <GitIcon />
            </FooterBtn>
          </RightIconWrapper>
        </Line>
        <Line style={{ justifyContent: "start" }}>
          <CopyRight>
            © {new Date().getFullYear()} grovs. All rights reserved. Grovs is a
            trademark of appssemble.
          </CopyRight>
        </Line>
      </Container>
    </FullPageContainer>
  );
};

export default Footer;
const Container = styled.div`
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  border-top: 1px solid #f6f4f2;
  padding: 60px 40px;

  @media (max-width: 990px) {
    padding: 20px;
  }
`;

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${lightThemeColors.background};
  position: relative;
  margin-top: auto;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  a {
    font-size: 14px;
    color: ${lightThemeColors.textColor};
  }
  @media (max-width: 990px) {
    align-items: start;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    max-width: 40px;
    max-height: 40px;
  }
  @media (max-width: 425px) {
    svg {
      max-width: 40px;
      max-height: 40px;
    }
  }
`;

const LinksWrapper = styled(Line)`
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  @media (max-width: 550px) {
    align-items: center;
    flex-direction: column;
  }
`;
const RightIconWrapper = styled(Line)``;
const FooterBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
  border: none;
  svg {
    max-width: 40px;
    max-height: 40px;
  }

  @media (max-width: 425px) {
    svg {
      max-width: 30px;
      max-height: 30px;
    }
  }
`;

const CopyRight = styled.p`
  color: ${lightThemeColors.textColor};
  font-size: 12px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
`;
