import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import { ReactComponent as Asset } from "../../assets/imgs/fold5Asset.svg";
import AnimatedTextButton from "../generic/AnimatedTextButton";
import { useNavigate } from "react-router-dom";

import {
  inView,
  motion,
  useInView,
  useScroll,
  useSpring,
  useTransform,
} from "framer-motion";
import { useOutletContext } from "react-router-dom";

const Fold5 = () => {
  const navigate = useNavigate();
  const goToSecurity = () => {
    navigate("/security");
  };

  return (
    <FullPageContainer>
      <Container>
        <Line style={{ gap: "40px" }}>
          <TextWrapper
            as={motion.div}
            initial={{ translateX: -100 }}
            whileInView={{ translateX: 0 }}
            viewport={{ once: true, margin: "75%" }}
            transition={{ duration: 1, type: "spring" }}
          >
            <h1>
              Our commitment to <span>security</span>
            </h1>
            <Subtitle>
              Our platform is designed with cutting-edge security mechanisms and
              protocols. Deployed in secure environments, we ensure your data is
              always protected. We are committed to safeguarding your
              information, with all connections fully encrypted and user data
              anonymized. Trust in our unwavering dedication to your privacy and
              security.
            </Subtitle>
            <AnimatedTextButton
              text={"See more details"}
              style={{
                borderBottom: `1px solid ${lightThemeColors.darkColor}`,
                fontWeight: "600",
              }}
              onClick={() => goToSecurity()}
            />
          </TextWrapper>
          <CardWrapper>
            <Asset />
          </CardWrapper>
        </Line>
      </Container>
    </FullPageContainer>
  );
};

export default Fold5;

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${lightThemeColors.linkGeneratorBg};
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 80px;
  gap: 40px;
  @media (max-width: 990px) {
    padding: 20px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    span {
      font-weight: 800;
      color: ${lightThemeColors.primaryColor};
    }

    @media (max-width: 990px) {
      font-size: 30px;
    }
  }
`;
const Subtitle = styled.p`
  color: rgba(37, 34, 34, 0.7);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  @media (max-width: 990px) {
    font-size: 16px;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically

  svg {
    width: 50%;

    @media (max-width: 990px) {
      width: 100%;
    }
  }
`;
const Line = styled.section`
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 990px) {
    flex-direction: column;
  }
`;
const BlackBanner = styled.section`
  display: flex;
  background: ${lightThemeColors.darkBannerBg};
  border-radius: 20px;
`;

const BannerItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 30px;
  border-right: 1px solid ${lightThemeColors.cardTextColorDark};
  &:last-child {
    border: none;
  }

  h1 {
    color: ${lightThemeColors.background};
    font-size: 24px;
    font-weight: 900;
    line-height: 28.8px;
    text-align: left;
  }

  p {
    color: ${lightThemeColors.linkGeneratorBg};
    opacity: 0.6;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
  }
`;
