import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import ReactPlayer from "react-player"; // Import ReactPlayer
import AnimatedTextButton from "../generic/AnimatedTextButton";

const Fold2 = () => {
  const [playing, setPlaying] = useState(false); // State to manage video playback

  const goToBenefits = () => {
    let element = document.getElementById("benefits");
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <FullPageContainer>
      <Container id={"product"}>
        <InfoWrapper>
          <CardWrapper>
            {/* Wrap the video and play button */}
            <VideoWrapper>
              <ReactPlayer
                url="https://appssemble-assets.s3.eu-north-1.amazonaws.com/linksquared/Welcome-2.mp4" // Replace with your actual S3 URL
                playing={playing} // Control playback with state
                controls={playing ?? false}
                width="100%"
                height="100%"
              />
              {!playing && (
                <PlayButton onClick={() => setPlaying(true)}>▶</PlayButton>
              )}
            </VideoWrapper>
          </CardWrapper>

          <TextWrapper>
            <h1>
              What is <span>grovs</span>?
            </h1>
            <Subtitle>
              Grovs is a platform that helps you create smart dynamic links,
              measure their effectiveness, and set up a referral system for your
              web and mobile apps.
            </Subtitle>

            <AnimatedTextButton
              text={"See benefits"}
              style={{
                borderBottom: `1px solid ${lightThemeColors.darkColor}`,
                fontWeight: "600",
              }}
              onClick={() => goToBenefits()}
            />
          </TextWrapper>
        </InfoWrapper>

        <Line>
          <BlackBanner>
            <BannerItem>
              <h1>Dynamic links</h1>
              <p>Create unlimited dynamic links via the web or SDKs.</p>
            </BannerItem>
            <BannerItem>
              <h1>Deep links</h1>
              <p>URLs that direct users to specific content within your app.</p>
            </BannerItem>
            <BannerItem>
              <h1>Analytics</h1>
              <p>
                Comprehensive attribution and analytics for all generated links.
              </p>
            </BannerItem>
            <BannerItem>
              <h1>Referrals</h1>
              <p>
                Automatic referral tracking to identify the source of every
                user.
              </p>
            </BannerItem>
          </BlackBanner>
        </Line>
      </Container>
    </FullPageContainer>
  );
};

export default Fold2;

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${lightThemeColors.background};
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 100px 80px 20px 80px;
  gap: 40px;

  @media (max-width: 990px) {
    padding: 20px;
    gap: 30px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    span {
      font-weight: 800;
      color: ${lightThemeColors.primaryColor};
    }
  }
`;
const Subtitle = styled.p`
  color: rgba(37, 34, 34, 0.7);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Maintain 16:9 aspect ratio */
  border-radius: 15px;
  overflow: hidden;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 50px;
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const Line = styled.section`
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 990px) {
    gap: 30px;
    flex-direction: column-reverse;
  }
`;
const InfoWrapper = styled.section`
  display: flex;
  align-items: center;
  gap: 40px;
  @media (max-width: 990px) {
    gap: 30px;
    flex-direction: column-reverse;
  }
`;
const BlackBanner = styled.section`
  display: flex;
  background: ${lightThemeColors.darkBannerBg};
  margin: 40px 0;
  border-radius: 10px;
  @media (max-width: 990px) {
    flex-direction: column;
    margin: 0px 0;
  }
`;

const BannerItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 30px;
  border-right: 1px solid ${lightThemeColors.labelColor};
  &:last-child {
    border: none;
  }

  h1 {
    color: ${lightThemeColors.background};
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
  }

  p {
    color: ${lightThemeColors.linkGeneratorBg};
    opacity: 0.6;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
  }
  @media (max-width: 990px) {
    border-right: unset;
    border-bottom: 1px solid ${lightThemeColors.labelColor};
  }
`;
